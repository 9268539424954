<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import {
  checkAge,
} from "@/state/validations/register";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      "Choose your package": "Escolha seu pacote",
      "Congratulations!": "Parabéns!",
      "Your account has been successfully created.":
        "Sua conta foi criada com sucesso.",
      Sponsor: "Indicador",
      "Sponsor is required.": "Indicador é obrigatório.",
      Name: "Nome",
      "Name is required.": "Nome é obrigatório.",
      Birthday: "Data de Nascimento",
      "Birthday is required.": "Data de nascimento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório.",
      "This value should be a valid e-mail.":
        "Este valor deve ser um e-mail válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Celular é obrigatório.",
      Register: "Cadastrar",
      "Click here to login": "Clique aqui para fazer o login",
      "Already have an account?": "Já possui uma conta?",
      Login: "Login",
      "The sponsor was not found.": "O indicador não foi encontrado.",
      "Fill in all required fields.": "Preencha todos os campos obrigatórios.",
      "Age must be over 18 years.": "A idade deve ser maior que 18 anos.",
    },
    es: {
      "Choose your package": "Elige tu paquete",
      "Congratulations!": "¡Felicidades!",
      "Your account has been successfully created.":
        "Tu cuenta ha sido creada satisfactoriamente.",
      Sponsor: "Indicador",
      "Sponsor is required.": "Se requiere patrocinador.",
      Name: "Nombre",
      "Name is required.": "Se requiere el nombre.",
      Birthday: "Fecha de Nacimiento",
      "Birthday is required.": "Fecha de nacimiento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "Se requiere correo electrónico.",
      "This value should be a valid e-mail.":
        "Este valor debe ser un correo electrónico válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Se requiere celular.",
      Register: "Registro",
      "Click here to login": "Haga clic aquí para ingresar",
      "Already have an account?": "¿Ya tienes una cuenta?",
      Login: "Iniciar Sesión",
      "The sponsor was not found.": "El patrocinador no fue encontrado.",
      "Fill in all required fields.": "Rellene todos los campos obligatorios.",
      "Age must be over 18 years.": "La edad debe ser mayor de 18 años.",
    },
  },
  components: {
    Layout,
    Languages,
  },
  data() {
    return {
      loading: {
        register: false,
        contract: false,
      },
      success: false,
      sponsor: {
        status: false,
        name: "",
        indication: "",
        avatar: "",
      },
      contract: {
        title: "",
        content: "",
        status: false,
      },
      user: {
        sponsor: "",
        name: "",

        type: "natural",

        birthday: "",

        cellphone: "",

        zipcode: "",
        city: "",
        state: "",

        pix: "",

        term: false,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  validations: {
    user: {
      sponsor: {},
      name: { required },

      birthday: { minLength: minLength(10), checkAge },

      voter_id: { required },
      voter_zone: { required },
      voter_section: { required },

      cellphone: { required },

      zipcode: { required },
      city: { required },
      state: { required },

      pix: { required },

      term: { required },
    },
  },
  methods: {
    ...mapActions("account", ["register", "token"]),
    getSponsor() {
      if (this.user.sponsor != this.sponsor.indication) {
        this.sponsor.name = "";
        this.sponsor.indication = this.user.sponsor;
        this.sponsor.avatar = "";

        api
          .post("user/sponsor", {
            indication: this.user.sponsor,
          })
          .then((response) => {
            if (response.data.status == "error") {
              this.sponsor.status = false;
              this.sponsor.name = "";
              this.sponsor.avatar = "";
              this.$router.push("/register");
            } else {
              this.sponsor.status = true;
              this.sponsor.name = response.data.user.name;
              this.sponsor.indication = response.data.user.indication;
              this.sponsor.avatar = response.data.user.avatar;

              localStorage.sponsor = JSON.stringify(response.data.user);

              this.$router.push("/register/" + response.data.user.indication);
            }
          });
      }
    },
    getContract() {
      api.get("user/contract").then((response) => {
        if (response.data.status == "success") {
          this.loading.contract = true;
          this.contract.status = response.data.contract.status;
          this.contract.title = response.data.contract.title;
          this.contract.content = response.data.contract.content;
        }
      });
    },
    searchCep() {
      if (this.user.zipcode.length == 9) {
        api.get("https://cep.m2n.com.br/" + this.user.zipcode.replace(/\D/g, "")).then((response) => {
          if (response.data.city == "") {
            this.alert.type = "alert-danger";
            this.alert.message = "O CEP informado é inválido.";
          } else {
            this.alert.type = "";
            this.alert.message = "";

            if (response.data.address) {
              this.user.address = response.data.address;
            }
            if (response.data.district) {
              this.user.district = response.data.district;
            }
            this.user.city = response.data.city;
            this.user.state = response.data.state;
          }
        });
      }
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { name, cellphone } = this.user;
        if (name && cellphone) {
          this.loading.register = true;
          api
            .post("user/eleitor", {
              sponsor: this.user.sponsor,
              name: this.user.name,
              type: this.user.type,

              birthday: this.user.birthday,

              voter_id: this.user.voter_id,
              voter_zone: this.user.voter_zone,
              voter_section: this.user.voter_section,

              cellphone: this.user.cellphone,

              zipcode: this.user.zipcode,
              city: this.user.city,
              state: this.user.state,

              pix: this.user.pix,
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.user.sponsor = "";
                this.user.name = "";
                this.user.type = "";

                this.user.birthday = "";

                this.user.voter_id = "";
                this.user.voter_zone = "";
                this.user.voter_section = "";

                this.user.cellphone = "";

                this.user.zipcode = "";
                this.user.city = "";
                this.user.state = "";

                this.user.pix = "";

                this.$v.$reset();

                this.$toast.success(response.data.message)

                this.success = true;
              } else {
                this.$toast.error(response.data.message)
              }

              this.loading.register = false;
            });
        }
      }
    },
  },
  mounted() {
    if (this.$route.params.sponsor) {
      this.user.sponsor = this.$route.params.sponsor;

      api
        .post("user/sponsor", {
          indication: this.user.sponsor,
        })
        .then((response) => {
          if (response.data.status == "error") {
            this.sponsor.status = false;
            this.sponsor.name = "";
            this.sponsor.avatar = "";
            this.$router.push("/register");
          } else {
            this.sponsor.status = true;
            this.sponsor.name = response.data.user.name;
            this.sponsor.indication = response.data.user.indication;
            this.sponsor.avatar = response.data.user.avatar;

            localStorage.sponsor = JSON.stringify(response.data.user);
          }
        });
    }

    if (!this.user.sponsor) {
      if (localStorage.sponsor) {
        this.user.sponsor = JSON.parse(localStorage.sponsor).indication;
        this.getSponsor();
      }
    }

    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 py-5 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="text-center">
            <img style="max-width: 250px" src="@/assets/images/logo.png" />
          </div>
          <div class="pt-5">
            <div v-if="success" class="text-center pt-4 pb-4">
              <h5>
                Seu cadastro foi realizado com sucesso!
              </h5>
            </div>
            <div v-else>
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form @submit.prevent="registerSubmit">
                <b-form-group id="name-group" class="d-none" :label="t('Tipo de Pessoa')" label-for="name">
                  <select v-model="user.type" class="custom-select mb-1">
                    <option value="">–</option>
                    <option value="natural">Pessoa Física</option>
                    <option value="legal">Pessoa Jurídica</option>
                  </select>
                  <div v-if="!$v.user.name.required" class="invalid-feedback">
                    {{ t("Name is required.") }}
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Dados Pessoais</span>
                </div>
                <b-form-group id="name-group" :label="t('Name')" label-for="name">
                  <b-form-input id="name" v-model="user.name" type="text" :class="{ 'is-invalid': $v.user.name.$error }"></b-form-input>
                  <div v-if="!$v.user.name.required" class="invalid-feedback">
                    {{ t("Name is required.") }}
                  </div>
                </b-form-group>
                <b-form-group id="birthday-group" :label="t('Birthday')" label-for="birthday">
                  <b-form-input id="birthday" v-model="user.birthday" v-mask="'##/##/####'" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.birthday.$error }"></b-form-input>
                  <div v-if="$v.user.birthday.$error" class="invalid-feedback">
                    <span v-if="!$v.user.birthday.required">{{
                      t("Birthday is required.")
                    }}</span>
                    <span v-if="!$v.user.birthday.minLength">{{
                      t("Birthday is required.")
                    }}</span>
                    <span v-if="!$v.user.birthday.checkAge">{{
                      t("Age must be over 18 years.")
                    }}</span>
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Título de Eleitor</span>
                </div>
                <b-form-group id="voter_id-group" :label="t('Número')" label-for="voter_id">
                  <b-form-input id="voter_id" v-model="user.voter_id" type="text" v-mask="'#### #### ####'" :class="{ 'is-invalid': $v.user.voter_id.$error }"></b-form-input>
                  <div v-if="!$v.user.voter_id.required" class="invalid-feedback">
                    {{ t("CNPJ is required.") }}
                  </div>
                </b-form-group>
                <b-form-group id="voter_zone-group" :label="t('Zona')" label-for="voter_zone">
                  <b-form-input id="voter_zone" v-model="user.voter_zone" type="text" :class="{ 'is-invalid': $v.user.voter_zone.$error }"></b-form-input>
                  <div v-if="!$v.user.voter_zone.required" class="invalid-feedback">
                    {{ t("CNPJ is required.") }}
                  </div>
                </b-form-group>
                <b-form-group id="voter_section-group" :label="t('Seção')" label-for="voter_section">
                  <b-form-input id="voter_section" v-model="user.voter_section" type="text" :class="{ 'is-invalid': $v.user.voter_section.$error }"></b-form-input>
                  <div v-if="!$v.user.voter_section.required" class="invalid-feedback">
                    {{ t("CNPJ is required.") }}
                  </div>
                </b-form-group>

                <template v-if="user.type == 'natural' || user.type == 'legal'">
                  <div class="divisor mb-3">
                    <span>Contatos</span>
                  </div>

                  <b-form-group id="cellphone-group" :label="t('Cellphone')" label-for="cellphone">
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <b-form-input id="cellphone" v-model="user.cellphone" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.cellphone.$error }" v-mask="'(##) #####-####'"></b-form-input>
                    </b-input-group>
                    <div v-if="!$v.user.cellphone.required" class="invalid-feedback">
                      {{ t("Cellphone is required.") }}
                    </div>
                  </b-form-group>

                  <div class="divisor mb-3">
                    <span>Endereço</span>
                  </div>

                  <b-form-group id="user.zipcode" label="CEP" label-for="user.zipcode">
                    <b-form-input v-on:blur="searchCep()" id="user.zipcode" v-model="user.zipcode" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.zipcode.$error }" autocomplete="off"
                      v-mask="'#####-###'"></b-form-input>
                    <div v-if="$v.user.zipcode.$error" class="invalid-feedback">
                      <span v-if="!$v.user.zipcode.required">O CEP é obrigatório.</span>
                      <span v-if="!$v.user.zipcode.invalid">O CEP informado é invalido.</span>
                    </div>
                  </b-form-group>
                  <b-form-group id="user.city" label="Cidade" label-for="user.city">
                    <b-form-input id="user.city" class="bg-soft-info" v-model="user.city" type="text" :class="{ 'is-invalid': $v.user.city.$error }" autocomplete="off"
                      readonly="readonly"></b-form-input>
                    <div v-if="$v.user.city.$error" class="invalid-feedback">
                      <span v-if="!$v.user.city.required">A cidade é obrigatória.</span>
                    </div>
                  </b-form-group>
                  <b-form-group id="user.state" label="Estado" label-for="user.state">
                    <b-form-input id="user.state" class="bg-soft-info" v-model="user.state" type="text" :class="{ 'is-invalid': $v.user.state.$error }" autocomplete="off" readonly="readonly"
                      v-mask="'XX'"></b-form-input>
                    <div v-if="$v.user.state.$error" class="invalid-feedback">
                      <span v-if="!$v.user.state.required">O estado é obrigatório.</span>
                    </div>
                  </b-form-group>

                  <div class="divisor mb-3">
                    <span>Chave Pix</span>
                  </div>

                  <b-form-group id="user.pix" label="Chave" label-for="user.pix">
                    <b-form-input v-on:blur="searchCep()" id="user.pix" v-model="user.pix" type="text" :class="{ 'is-invalid': $v.user.pix.$error }" autocomplete="off"></b-form-input>
                    <div v-if="$v.user.pix.$error" class="invalid-feedback">
                      <span v-if="!$v.user.pix.required">A chave pix é obrigatório.</span>
                    </div>
                  </b-form-group>

                  <div v-if="loading.contract && contract.status == 1">
                    <div v-html="contract.content" class="border p-3 overflow-auto" style="height: 200px"></div>
                    <div class="pt-3">
                      <label>
                        <input type="checkbox" v-model="user.term" :class="{ 'is-invalid': $v.user.term.$error }" />
                        {{ contract.title }}
                      </label>
                    </div>
                  </div>
                  <div class="mt-4">
                    <b-button :disabled="loading.register == true ||
                      (!user.term && contract.status == 1)
                      " type="submit" variant="default" class="btn-lg btn-block">
                      {{ t("Register") }}
                      <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                    </b-button>
                  </div>
                </template>
              </b-form>
            </div>
          </div>
          <div class="mt-4 text-center">
            <Languages class="d-none" type="dropup"></Languages>
            <div v-if="sponsor.status && sponsor.name" class="mb-4">
              <span class="text-uppercase font-size-11 text-muted"><small>REF. {{ sponsor.indication }}</small></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}

.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}

.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
